<template>
  <div>
    <el-card class="box-card IDen-card-header">
      <el-row :gutter="5">
        <el-form :model="headerValidateForm" ref="numberValidateForm" label-width="80px" class="demo-ruleForm">
          <el-col :xl="6" :md="24" :lg="4" :sm="24">
            <el-form-item label="Keyword:" prop="keywords" :rules="[]">
              <el-input type="age" v-model="headerValidateForm.keywords"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xl="4" :md="24" :lg="5" :sm="24">
            <el-form-item>
              <el-button style="background-color:#295f10;border: 0" type="primary" @click="submitForm('numberValidateForm')">Inquire</el-button>
              <el-button style="color: #c1c1c1;border: #c1c1c1 1px solid" @click="resetForm('numberValidateForm')">Reset</el-button>
            </el-form-item>
          </el-col>
          <el-col :xl="8" :md="24" :lg="6" :sm="24">
            <el-form-item prop="data">
              <el-date-picker
                  v-model="headerValidateForm.data"
                  type="daterange"
                  value-format="yyyy-MM-dd hh:mm:ss"
                  range-separator="→"
                  start-placeholder="Start data"
                  end-placeholder="End data">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col  :xl="6" :md="24" :lg="9" :sm="24" style="text-align:center;">
            <el-form-item prop="value">
              <el-select v-model="headerValidateForm.value" clearable placeholder="state">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
    <el-card class="box-card back-card-class">
      <el-table
          :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
          style="width: 100%"
          :header-cell-style="{background:' #fafafa',borderTop:' #e9e9e9 1px solid'} "
      >
        <el-table-column
            type="index"
            label="number"
            width="120px"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="Name"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="email"
            label="Email"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="organization"
            label="Organization"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="department"
            label="Department"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="country"
            label="Country"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            prop="dateTime"
            label="Time"
            align="center"
            width="180">
        </el-table-column>
        <el-table-column
            label="State"
            align="center"
            width="180">
          <template slot-scope="scope">
            <div v-if="scope.row.state===1" style="display: flex;align-items: center;justify-content: center">
              <p style="color:#568041;cursor: pointer">Replied</p><p style="width:8px;height:8px;border-radius: 50%;background-color:#01c260;margin-left:5px"></p>
            </div>
            <div v-if="scope.row.state===0" style="display: flex;align-items: center;justify-content: center">
              <p style="color:#568041;cursor: pointer">Unanswered</p><p style="width:8px;height:8px;border-radius: 50%;background-color:orange;margin-left: 5px"></p>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="Operate"
            align="center"
            fixed="right"
            width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.state===1">
              <el-button :style="{ color:scope.state===1?'#7a627f':'#3d6d27' }" :disabled="true" class="Reply" @click="DetailsTable(scope.row)">Reply</el-button>
            </div>
            <div v-if="scope.row.state===0">
              <el-button :style="{ color:scope.state===1?'#7a627f':'#3d6d27' }" :disabled="false" class="Reply" @click="DetailsTable(scope.row)">Reply</el-button>
            </div>
          </template>
        </el-table-column>

      </el-table>
      <div class="back-page">
        <el-pagination
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="tableDataLength">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  name: "index",
  data(){
    return {
      //每页显示条数
      pageSize:7,
      //页码初始化
      currentPage: 1,
      //搜索数据
      headerValidateForm:{
        keywords:'',
        data:'',
        value:''
      },
      //查询
      options: [{
        value: '',
        label: 'All'
      }, {
        value:1,
        label: 'Unanswered'
      }, {
        value:0,
        label: 'Replied'
      }],
      tableData: [],
      tableDataLength:7
    }
  },
  mounted() {
    this.getDate()
    document.getElementsByClassName("el-pagination__jump")[0].childNodes[0].nodeValue = "Turn to page";
  },
  methods:{
    getDate() {
      this.axios.get('/api/data-request', {
        headers:{
          Authorization:window.sessionStorage.getItem('assign__token')
        },
        params:{
          PageIndex:1,
          PageSize:7
        }
      }).then(data=>{
        this.tableData=data.data.content
        this.tableDataLength=data.data.totalElements
      })
    },
    //分页
    handleCurrentChange(A){
      this.axios.get('/api/data-request', {
        headers:{
          Authorization:window.sessionStorage.getItem('assign__token')
        },
        params:{
          PageIndex:A,
          PageSize:7
        }
      }).then(data=>{
        this.tableData=data.data.content
        this.tableDataLength=data.data.totalElements
      })
    },
    //发送邮件
    DetailsTable(row){
      this.axios.post('/api/data-request-email',{Id:row.id},
          {
            headers:{
              Authorization:window.sessionStorage.getItem('assign__token')
            },
          }
      ).then(()=>{
        this.$message.success('邮件发送成功^.^');
        this.reload()
      })
    },
    //查询数据
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios.get('/api/data-request',{
            headers:{
              Authorization:window.sessionStorage.getItem('assign__token')
            },
            params:{
              KeyWords:this.headerValidateForm.keywords,
              StartTime:this.headerValidateForm.data[0]===undefined?'':this.headerValidateForm.data[0],
              EndTime:this.headerValidateForm.data[1]===undefined?'':this.headerValidateForm.data[1],
              State:this.headerValidateForm.value
            }
          }).then(data=>{
            this.tableData=data.data.content
            this.tableDataLength=data.data.totalElements
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.getDate()
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped lang="scss">
.IDen-card-header{
  .el-form-item{
    height: 20px;
  }
}
.back-card-class{
  margin-top: 15px;
  height: 76vh;
  position: relative;
  .back-page{
    height: 60px;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
  }
  .Reply:hover{
    color:#7a627f;
    border: 0;
    background-color: white;
  }
  .Reply{
    font-size: 18px;
    border: 0;
  }
}

</style>